<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/volunteers/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/volunteers/access', 'read')">

			<app-input-select label="Day" v-model="model.day" :validation="$v.model.day" :options="references.days" placeholder="Select day" />
			<app-input-select label="Time" v-model="model.start" :validation="$v.model.start" :options="references.times" placeholder="Select time" />
			<app-input-select label="Duration" v-model="model.duration" :validation="$v.model.duration" :options="references.durations" placeholder="Select duration" />
			<app-input-text label="Capacity" placeholder="Enter number..." v-model="model.capacity" :validation="$v.model.capacity" :numeric="true" />
			
		</app-content-box>

		<app-content-box icon="volunteers.assignments" :not-padded="true" title="Volunteers" v-if="!isNew">

			<app-table :empty="!references.assignments.length" emptyMessage="There are no volunteers assigned.">

				<app-table-head :layout="layout">

					<app-table-column text="Reference" />
					<app-table-column text="Name" />
					<app-table-column text="Date" />
					<app-table-column />

				</app-table-head>

				<app-table-body>

					<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in references.assignments" :key="item.id">

						<app-table-cell-text :text="item.badge.reference" />
						<app-table-cell-text :text="item.badge.name" />
						<app-table-cell-text :text="item.date | localDate('MM/DD/YYYY h:mma')" />
						<app-table-cell-action icon="remove" theme="red" v-tooltip="'Remove volunteer'" v-on:click="onRemoveClick(item.id)" />

					</app-table-row>

				</app-table-body>

			</app-table>

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/volunteers/access', 'read'),
			layout: '120px auto 240px 24px',
			references: {
				assignments: [],
				days: [],
				durations: [],
				times: []
			},
			model: {
				id: false,
				day: 0,
				duration: 0,
				start: 0,
				capacity: 0
			}
		}

	},

	validations: {
		model: {
			day: {
				required
			},
			duration: {
				required
			},
			start: {
				required
			},
			capacity: {
				required
			}
		}
	},

	methods: {

		onEditClick: function(id) {

			this.$router.push({
				name: 'Convention.Volunteers.Assignments.Edit',
				params: {
					id: id
				},
				query: {
					redirect: 'slot',
					slot: this.item.id,
					role: this.item.role.id
				}
			})

		},

		onRemoveClick: function(id) {

			this.$router.push({
				name: 'Convention.Volunteers.Assignments.Delete',
				params: {
					id: id
				},
				query: {
					redirect: 'slot',
					slot: this.item.id,
					role: this.item.role.id
				}
			})

		}

	}

}

</script>

<style scoped>

</style>